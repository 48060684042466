import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  customers: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the customer
export const fetchCustomers = createAsyncThunk(
  "fetchCustomers/customers",
  async ({ enqueueSnackbar, limit, page = 0 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`users`, {
        params: {
          limit,
          page: page + 1,
        },
      });

      console.log(response.data, 'fdsf')

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete teamMember
export const deleteTeamMember = createAsyncThunk(
  "deleteTeamMember/customers",
  async ({ enqueueSnackbar, handleClose, userId }, thunkApi) => {
    try {
      await axiosInstance.delete(`users/${userId}`);
      return {
        data: userId,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const customerslice = createSlice({
  name: "team",
  initialState,
  extraReducers: (builder) => {
    // TODO: fetch customer
    builder.addCase(fetchCustomers.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.customers = action.payload;
    });

    builder.addCase(fetchCustomers.rejected, (state, action) => {
      state.fetchLoading = false;
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: delete category
    builder.addCase(deleteTeamMember.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      state.isLoading = false;
      state.customers.data = state.customers.data.filter(
        (category) => category.id !== action.payload.data
      );
      action.payload.enqueueSnackbar("User is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });
  },
});

export default customerslice.reducer;
