import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  tags: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the tags
export const fetchTags = createAsyncThunk(
  "fetchTags/tags",
  async ({ enqueueSnackbar, search }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`tags`, {
        params: {
          ...(search !== "" && {
            search,
          }),
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create tag
export const createTag = createAsyncThunk(
  "createTag/tags",
  async ({ data, enqueueSnackbar, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`tags`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update tag
export const updateTag = createAsyncThunk(
  "updateTag/tags",
  async ({ data, enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`tags/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete tag
export const deleteTag = createAsyncThunk(
  "deleteTag/tags",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`tags/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get tag
    builder.addCase(fetchTags.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.tags = action.payload;
    });

    builder.addCase(fetchTags.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: create tag
    builder.addCase(createTag.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createTag.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tags.data = [action.payload.data, ...state.tags.data];
      state.tags.meta.total = state.tags.meta.total + 1;
      action.payload.enqueueSnackbar("Tag is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createTag.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: update tag
    builder.addCase(updateTag.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateTag.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tags.data = state.tags.data.map((tag) => {
        if (tag.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return tag;
        }
      });
      action.payload.enqueueSnackbar("Tag is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateTag.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: delete tag
    builder.addCase(deleteTag.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteTag.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tags.data = state.tags.data.filter(
        (tag) => tag.id !== action.payload.data
      );
      action.payload.enqueueSnackbar("Tag is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteTag.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });
  },
});

export default tagSlice.reducer;

export const { resetLoadings } = tagSlice.actions;
