import { lazy, Suspense } from "react";
import LoadingScreen from "../components/loading-screen";
// components

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));

// TODO: general section
export const LandingPage = Loadable(
  lazy(() => import("../pages/general/Dashboard"))
);

// TODO: my profile

export const MyProfile = Loadable(
  lazy(() => import("../pages/myProfile/MyProfile"))
);

export const Orders = Loadable(lazy(() => import("../pages/orders/Orders")));

// export const Profile = Loadable(lazy(() => import("../pages/general/Profile")));

export const Customers = Loadable(
  lazy(() => import("../pages/customers/Customers"))
);

export const Banners = Loadable(lazy(() => import("../pages/banners/Banners")));
export const Offers = Loadable(lazy(() => import("../pages/offers/Offers")));

// TODO: entries
export const Brands = Loadable(lazy(() => import("../pages/entries/Brands")));

export const Colors = Loadable(lazy(() => import("../pages/entries/Colors")));

export const Sizes = Loadable(lazy(() => import("../pages/entries/Sizes")));

export const Categories = Loadable(
  lazy(() => import("../pages/entries/Categories"))
);

export const Tags = Loadable(lazy(() => import("../pages/entries/Tags")));

// TODO: settings
export const Settings = Loadable(
  lazy(() => import("../pages/settings/Settings"))
);

// TODO: supports

// TODO: emergency contacts
export const EmergencyContacts = Loadable(
  lazy(() => import("../pages/supports/EmergencyContacts"))
);
