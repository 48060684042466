import { Navigate, Outlet, useRoutes } from "react-router-dom";

import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
import { PATH_AFTER_LOGIN } from "../config-global";
import Products from "../pages/entries/Products";
import { Layout } from "../routers";
import {
  Banners,
  Brands,
  Categories,
  Colors,
  Customers,
  EmergencyContacts,
  LandingPage,
  LoginPage,
  MyProfile,
  Offers,
  Orders,
  Page404,
  Settings,
  Sizes,
  Tags,
} from "./elements";

//

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },

    {
      path: "/admin",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        {
          path: "orders",
          element: (
            <Layout>
              <Orders />
            </Layout>
          ),
        },

        {
          path: "dashboard",
          element: (
            <Layout>
              <LandingPage />
            </Layout>
          ),
        },

        {
          path: "my-profile",
          element: (
            <Layout>
              <MyProfile />
            </Layout>
          ),
        },

        // TODO: users
        {
          path: "/admin/users",
          children: [
            {
              element: <Navigate to="/admin/users/admins" replace />,
              index: true,
            },

            {
              path: "customers",
              element: (
                <Layout>
                  <Customers />
                </Layout>
              ),
            },
          ],
        },

        // TODO: Entries
        {
          path: "/admin/entries",
          children: [
            {
              element: <Navigate to="/admin/entries/brands" replace />,
              index: true,
            },

            {
              path: "products",
              element: (
                <Layout>
                  <Products />
                </Layout>
              ),
            },

            {
              path: "offers",
              element: (
                <Layout>
                  <Offers />
                </Layout>
              ),
            },

            {
              path: "banners",
              element: (
                <Layout>
                  <Banners />
                </Layout>
              ),
            },

            {
              path: "brands",
              element: (
                <Layout>
                  <Brands />
                </Layout>
              ),
            },

            {
              path: "colors",
              element: (
                <Layout>
                  <Colors />
                </Layout>
              ),
            },

            {
              path: "categories",
              element: (
                <Layout>
                  <Categories />
                </Layout>
              ),
            },

            {
              path: "tags",
              element: (
                <Layout>
                  <Tags />
                </Layout>
              ),
            },

            {
              path: "sizes",
              element: (
                <Layout>
                  <Sizes />
                </Layout>
              ),
            },
          ],
        },

        // TODO: Settings
        {
          path: "/admin/settings",
          children: [
            {
              element: <Navigate to="/admin/settings" replace />,
              index: true,
            },

            {
              path: "refer-delivery",
              element: (
                <Layout>
                  <Settings />
                </Layout>
              ),
            },

            {
              path: "brands",
              element: (
                <Layout>
                  <Brands />
                </Layout>
              ),
            },

            {
              path: "colors",
              element: (
                <Layout>
                  <Colors />
                </Layout>
              ),
            },

            {
              path: "categories",
              element: (
                <Layout>
                  <Categories />
                </Layout>
              ),
            },

            {
              path: "sizes",
              element: (
                <Layout>
                  <Sizes />
                </Layout>
              ),
            },
          ],
        },

        // TODO: supports
        {
          path: "/admin/supports",
          children: [
            {
              element: (
                <Navigate to="/admin/supports/emergency-contacts" replace />
              ),
              index: true,
            },

            {
              path: "emergency-contacts",
              element: (
                <Layout>
                  <EmergencyContacts />
                </Layout>
              ),
            },
          ],
        },
      ],
    },

    {
      // element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
