import { CiGrid41 } from "react-icons/ci";
import { FaProductHunt } from "react-icons/fa";
import { IoMdResize } from "react-icons/io";
import { IoPricetagsSharp, IoSettings } from "react-icons/io5";
import {
  MdCategory,
  MdLocalOffer,
  MdOutlineInvertColors,
  MdPages,
} from "react-icons/md";
import { SiBrandfolder, SiSentry } from "react-icons/si";

import { HiOutlineUsers, HiShoppingCart } from "react-icons/hi";

export const ROOTS_DASHBOARD = "/admin";

export const PATH_AUTH = {
  login: "/login",
  privacy: "/privacy-policy",
};

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const PATH_DASHBOARD = path(ROOTS_DASHBOARD, "/orders");

const menu = [
  {
    title: "Orders",
    icon: <HiShoppingCart size={35} color="#ddd" />,
    path: path(ROOTS_DASHBOARD, "/orders"),
  },

  {
    title: "Products",
    path: path(ROOTS_DASHBOARD, "/entries/products"),
    icon: <FaProductHunt size={35} />,
  },

  {
    title: "Offers",
    path: path(ROOTS_DASHBOARD, "/entries/offers"),
    icon: <MdLocalOffer size={35} />,
  },

  {
    title: "Banners",
    path: path(ROOTS_DASHBOARD, "/entries/banners"),
    icon: <MdPages size={35} />,
  },

  {
    title: "Dashboard",
    icon: <CiGrid41 size={35} color="#ddd" />,
    path: path(ROOTS_DASHBOARD, "/dashboard"),
  },

  {
    title: "Entries",
    icon: <SiSentry size={35} color="#ddd" />,
    childrens: [
      {
        title: "Brands",
        path: path(ROOTS_DASHBOARD, "/entries/brands"),
        icon: <SiBrandfolder size={20} />,
      },

      {
        title: "Categories",
        path: path(ROOTS_DASHBOARD, "/entries/categories"),
        icon: <MdCategory size={20} />,
      },

      {
        title: "Tags",
        path: path(ROOTS_DASHBOARD, "/entries/tags"),
        icon: <IoPricetagsSharp size={20} />,
      },

      {
        title: "Sizes",
        path: path(ROOTS_DASHBOARD, "/entries/sizes"),
        icon: <IoMdResize size={20} />,
      },

      {
        title: "Colors",
        path: path(ROOTS_DASHBOARD, "/entries/colors"),
        icon: <MdOutlineInvertColors size={20} />,
      },
    ],
  },

  {
    title: "Customers",
    icon: <HiOutlineUsers size={35} color="#ddd" />,
    path: path(ROOTS_DASHBOARD, "/users/customers"),
  },

  {
    title: "Settings",
    icon: <IoSettings size={20} />,
    path: path(ROOTS_DASHBOARD, "/settings/refer-delivery"),
  },

  // {
  //   title: "Support",
  //   icon: <HiOutlineUserCircle size={35} color="#ddd" />,
  //   childrens: [
  //     {
  //       title: "FAQs",
  //       path: path(ROOTS_DASHBOARD, "/supports/faqs"),
  //       icon: <HiOutlineQuestionMarkCircle size={20} />,
  //     },
  //     {
  //       title: "Cancel Reasons",
  //       path: path(ROOTS_DASHBOARD, "/supports/cancel-reasons"),
  //       icon: <BiCalendarX size={20} />,
  //     },

  //     {
  //       title: "Emergency Contacts",
  //       path: path(ROOTS_DASHBOARD, "/supports/emergency-contacts"),
  //       icon: <RiContactsBookLine size={20} />,
  //     },
  //   ],
  // },
];

export default menu;
