import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  orders: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: fetch all the orders
export const fetchOrders = createAsyncThunk(
  "fetchOrders/orders",
  async (
    { enqueueSnackbar, search, limit = 10, page = 0, filter, tabFilter },
    thunkApi
  ) => {
    try {
      const response = await axiosInstance.get(`orders`, {
        params: {
          ...(search !== "" && {
            search,
          }),
          limit,
          page: page + 1,
          ...(filter.payment_status !== "" && {
            payment_status: filter.payment_status,
          }),
          ...(tabFilter !== "" && { status: tabFilter }),
        },
      });

      return {
        data: response.data.data.data,
        meta: {
          total: response.data.data.total,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: create order
export const createOrder = createAsyncThunk(
  "createOrder/orders",
  async (
    { data, enqueueSnackbar, handleClose, activeTab, setActiveTab },
    thunkApi
  ) => {
    try {
      const response = await axiosInstance.post(`orders`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
        activeTab,
        setActiveTab,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update order
export const updateOrder = createAsyncThunk(
  "updateOrder/orders",
  async ({ data, enqueueSnackbar, handleClose, code }, thunkApi) => {
    try {
      const response = await axiosInstance.patch(`orders/${code}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update status of order
export const updateOrderStatus = createAsyncThunk(
  "updateOrderStatus/orders",
  async (
    { data, enqueueSnackbar, handleClose, code, setActiveTab },
    thunkApi
  ) => {
    try {
      const response = await axiosInstance.patch(`update-order/${code}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
        setActiveTab,
        status: data.status,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete order
export const deleteOrder = createAsyncThunk(
  "deleteOrder/orders",
  async ({ enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      await axiosInstance.delete(`orders/${id}`);
      return {
        data: id,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetLoadings: (state) => {
      state.isLoading = false;
      state.fetchLoading = false;
    },
  },
  extraReducers: (builder) => {
    // TODO: get order
    builder.addCase(fetchOrders.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.orders = action.payload;
    });

    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(
          action.payload.error || action.payload.error.message || "Error"
        ),
        {
          variant: "error",
        }
      );
    });

    // TODO: create order
    builder.addCase(createOrder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders.data = [action.payload.data, ...state.orders.data];
      state.orders.meta.total = state.orders.meta.total + 1;
      action.payload.enqueueSnackbar("Order is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
      action.payload.setActiveTab(action.payload.activeTab);
    });

    builder.addCase(createOrder.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: update order
    builder.addCase(updateOrder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders.data = state.orders.data.map((order) => {
        if (order.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return order;
        }
      });
      action.payload.enqueueSnackbar("Order is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateOrder.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: update order status
    builder.addCase(updateOrderStatus.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateOrderStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders.data = state.orders.data.map((order) => {
        if (order.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return order;
        }
      });
      action.payload.enqueueSnackbar("Order is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
      action.payload.setActiveTab &&
        action.payload.setActiveTab(action.payload.status || "Processing");
    });

    builder.addCase(updateOrderStatus.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: delete order
    builder.addCase(deleteOrder.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.orders.data = state.orders.data.filter(
        (order) => order.id !== action.payload.data
      );
      action.payload.enqueueSnackbar("Order is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });
  },
});

export default orderSlice.reducer;

export const { resetLoadings } = orderSlice.actions;
