import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  offers: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: create the offer
export const createOffer = createAsyncThunk(
  "createOffer/offers",
  async ({ enqueueSnackbar, data, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`banners`, data);
      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: fetch all the offer
export const fetchOffers = createAsyncThunk(
  "fetchOffers/offers",
  async ({ enqueueSnackbar, limit, page = 0 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`banners`, {
        params: {
          limit,
          page: page + 1,
          type: "ad",
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update offer
export const updateOffer = createAsyncThunk(
  "updateOffer/offers",
  async ({ data, enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`banners/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete offer
export const deleteOffer = createAsyncThunk(
  "deleteOffer/offers",
  async ({ enqueueSnackbar, handleClose, offerId }, thunkApi) => {
    try {
      await axiosInstance.delete(`banners/${offerId}`);
      return {
        data: offerId,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const offerSlice = createSlice({
  name: "offer",
  initialState,
  extraReducers: (builder) => {
    // TODO: fetch offer
    builder.addCase(fetchOffers.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.offers = action.payload;
    });

    builder.addCase(fetchOffers.rejected, (state, action) => {
      state.fetchLoading = false;
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: create offer
    builder.addCase(createOffer.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offers.data = [action.payload.data, ...state.offers.data];
      state.offers.meta.total = state.offers.meta.total + 1;
      action.payload.enqueueSnackbar("Offer is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createOffer.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: update offer
    builder.addCase(updateOffer.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offers.data = state.offers.data.map((offer) => {
        if (offer.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return offer;
        }
      });
      action.payload.enqueueSnackbar("Offer is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateOffer.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: delete offer
    builder.addCase(deleteOffer.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offers.data = state.offers.data.filter(
        (offer) => offer.id !== action.payload.data
      );
      action.payload.enqueueSnackbar("Offer is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteOffer.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });
  },
});

export default offerSlice.reducer;
