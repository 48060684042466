import { Button, Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { default as React } from "react";
import { TitleMd } from "../../design/Title";
import Iconify from "../../iconify";

export function EditDialog({
  open,
  maxWidth = "lg",
  handleClose,
  title,
  handler,
  children,
}) {
  return (
    <>
      <Dialog open={open} onClose={handler} maxWidth={maxWidth} fullWidth>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ position: "sticky", top: 0, zIndex: 100, background: "white" }}
        >
          <DialogTitle>
            <TitleMd>{title}</TitleMd>
          </DialogTitle>
          <Button onClick={handleClose}>
            <Iconify icon="line-md:close" color="red" />
          </Button>
        </Stack>
        <Divider />
        {children}
      </Dialog>
    </>
  );
}
