import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fetchLoading: false,
  banners: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

// TODO: create the banner
export const createBanner = createAsyncThunk(
  "createBanner/banners",
  async ({ enqueueSnackbar, data, handleClose }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`banners`, data);
      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: fetch all the banner
export const fetchBanners = createAsyncThunk(
  "fetchBanners/banners",
  async ({ enqueueSnackbar, limit, page = 0 }, thunkApi) => {
    try {
      const response = await axiosInstance.get(`banners`, {
        params: {
          limit,
          page: page + 1,
          type: "banner",
        },
      });

      return {
        data: response.data.data,
        meta: {
          total: response.data.data.length,
        },
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: update banner
export const updateBanner = createAsyncThunk(
  "updateBanner/banners",
  async ({ data, enqueueSnackbar, handleClose, id }, thunkApi) => {
    try {
      const response = await axiosInstance.post(`banners/${id}`, data);

      return {
        data: response.data.data,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

// TODO: delete banner
export const deleteBanner = createAsyncThunk(
  "deleteBanner/banners",
  async ({ enqueueSnackbar, handleClose, bannerId }, thunkApi) => {
    try {
      await axiosInstance.delete(`banners/${bannerId}`);
      return {
        data: bannerId,
        handleClose,
        enqueueSnackbar,
      };
    } catch (error) {
      return thunkApi.rejectWithValue({ error, enqueueSnackbar });
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers: (builder) => {
    // TODO: fetch banner
    builder.addCase(fetchBanners.pending, (state, _) => {
      state.fetchLoading = true;
    });

    builder.addCase(fetchBanners.fulfilled, (state, action) => {
      state.fetchLoading = false;
      state.banners = action.payload;
    });

    builder.addCase(fetchBanners.rejected, (state, action) => {
      state.fetchLoading = false;
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: create banner
    builder.addCase(createBanner.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(createBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.banners.data = [action.payload.data, ...state.banners.data];
      state.banners.meta.total = state.banners.meta.total + 1;
      action.payload.enqueueSnackbar("Banner is created successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(createBanner.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: update banner
    builder.addCase(updateBanner.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(updateBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.banners.data = state.banners.data.map((banner) => {
        if (banner.id === action.payload.data.id) {
          return action.payload.data;
        } else {
          return banner;
        }
      });
      action.payload.enqueueSnackbar("Banner is updated successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(updateBanner.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });

    // TODO: delete banner
    builder.addCase(deleteBanner.pending, (state, _) => {
      state.isLoading = true;
    });

    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.banners.data = state.banners.data.filter(
        (banner) => banner.id !== action.payload.data
      );
      action.payload.enqueueSnackbar("Banner is deleted successfully.", {
        variant: "success",
      });
      action.payload.handleClose && action.payload.handleClose();
    });

    builder.addCase(deleteBanner.rejected, (state, action) => {
      state.isLoading = false;
      action.payload.enqueueSnackbar(
        JSON.stringify(action.payload.error || action.payload.error.message),
        {
          variant: "error",
        }
      );
    });
  },
});

export default bannerSlice.reducer;
